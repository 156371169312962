import React from "react";
import {
    // Avatar,
    Col,
    // Dropdown,
    // Menu,
    Row,
} from "antd";
// import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/Logo-Tow-Pro-onDark-RGB.png";

// Helpers
// import { clearUser } from "../helpers/user";
// import { clearToken } from "../helpers/auth";

// const menu = (
//     <Menu style={{ minWidth: 150 }}>
//         <Menu.Item>
//             <Link
//                 to="/login"
//                 onClick={() => {
//                     // clearUser();
//                     // clearToken();
//                 }}
//                 style={{ marginTop: 4, marginBottom: 4 }}
//             >
//                 <LogoutOutlined />
//                 <span style={{ paddingLeft: 8 }}>Logout</span>
//             </Link>
//         </Menu.Item>
//     </Menu>
// );

const TopNavigation = () => {
    return (
        <Row>
            <Col span={4}>
                <div className="logo" style={{ maxHeight: 54 }}>
                    <Link to="/">
                        <img
                            src={logo}
                            alt="Main Logo"
                            style={{ maxHeight: 54 }}
                        />
                    </Link>
                </div>
            </Col>
            {/* <Col
                span={20}
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "right",
                }}
            >
                <Dropdown overlay={menu}>
                    <div style={{ marginRight: 15 }}>
                        <div className="ant-dropdown-link">
                            <Avatar
                                icon={<UserOutlined />}
                                style={{
                                    backgroundColor: "#87d068",
                                    marginRight: 5,
                                }}
                            />
                            <span
                                style={{
                                    color: "#404040",
                                    cursor: "default",
                                    display: "inline-block",
                                    fontSize: 16,
                                    height: 64,
                                    lineHeight: "64px",
                                    marginLeft: 6,
                                    marginRight: 15,
                                }}
                            >
                                First Last
                            </span>
                            <DownOutlined />
                        </div>
                    </div>
                </Dropdown>
            </Col> */}
        </Row>
    );
};

export default TopNavigation;
