import React from "react";
import "./PublicLayout.scss";
import { Layout } from "antd";
import { Switch, Route } from "react-router-dom";

// Components
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import Home from "../../../pages/home/Home";

const { Header, Content } = Layout;

const PublicLayout = () => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header style={{ background: "#2b2b2b", height: "70px" }}>
                <TopNavigation />
            </Header>
            <Layout>
                <Content style={{ margin: "16px" }}>
                    <div style={{ padding: 24 }}>
                        <Switch>
                            <Route path="/" render={() => <Home />} />
                        </Switch>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default PublicLayout;
