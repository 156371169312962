export const vinMask = (vin: string = "") => {
    const lastSix = vin.substr(vin.length - 6);
    return "******" + lastSix;
};

export const formatPhone = (phone: number) => {
    const phoneLength = phone.toString().split("").length;
    let phoneSplit = phone.toString().split("");
    if (phoneLength === 10) {
        phoneSplit.splice(0, 0, "(");
        phoneSplit.splice(4, 0, ")");
        phoneSplit.splice(8, 0, "-");
        const phoneJoin = phoneSplit.join("");
        return phoneJoin;
    }
    if (phoneLength === 11) {
        phoneSplit.splice(1, 0, "(");
        phoneSplit.splice(5, 0, ")");
        phoneSplit.splice(9, 0, "-");
    }
    const phoneJoin = phoneSplit.join("");
    return phoneJoin;
};

export const states = () => {
    return [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ];
};
