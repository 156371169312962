import { useState } from "react";
import { Row, message } from "antd";
import "./Home.scss";
import { requestSearch } from "../../core/apollo/search/search.service";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

// Components
import Informationals from "../../core/components/Informationals/Informationals";
import VehiclesList from "../../core/components/VehiclesList/VehiclesList";
import TowForm from "../../core/components/TowForm/TowForm";

const Home = () => {
    const [vehicles, setVehicles] = useState<any>([]);

    const submitForm = (values: any) => {
        console.log("🚀 ~ values", values)
        requestSearch({
            // createdAt: values.date,
            rangeStart: values.range[0],
            rangeEnd: values.range[1],
            make: values.make.value,
            model: values.model.value,
            vin: values.vin,
            licensePlateNumber: values.sp,
            vehicleYear: values.year
                ? parseInt(dayjs(values.year).format("YYYY"))
                : values.year,
            towLocation: values.towLocation
        })
            .then((resp) => {
                if (isEmpty(resp)) {
                    message.warning(
                        "It looks like no vehicles with the selected details were found."
                    );
                }
                console.log(resp);
                setVehicles(resp);
            })
            .catch((err) => {
                message.error(
                    "Oops! It looks like something went wrong. Please try again later."
                );
                console.log("ERROR", err);
            });
    };

    return (
        <div className="home-page">
            <Row justify="center" style={{ marginBottom: "1rem" }}>
                <h1>My car was towed, now what do I do?</h1>
            </Row>
            <Informationals />
            <Row justify="center">
                <h2>Let’s find your vehicle</h2>
            </Row>
            <TowForm submitForm={submitForm} />
            {!isEmpty(vehicles) && <VehiclesList vehicles={vehicles} />}
        </div>
    );
};

export default Home;
