import React, { useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import "./TowForm.scss";
import DebounceSelect from "./DebounceSelect";
import FormColumn from "./FormColumn";
import { makesSearch, modelsSearch } from "../../apollo/search/search.service";
import { states } from "../../helpers/dataFormats";
const { Option } = Select;
const { RangePicker } = DatePicker;

const TowForm = (props: any) => {
    const [makes, setMakes] = useState<any>();
    const [models, setModels] = useState<any>();
    const [makeSelected, setMakeSelected] = useState<boolean>(true);
    const [form] = Form.useForm();

    const { submitForm } = props;

    const fetchMakes = (value: any) => {
        makesSearch({ search: value })
            .then((resp) => {
                setMakes(resp);
            })
            .catch((err) => console.log("ERR", err));
    };

    const fetchModels = (value: any) => {
        const makeValue = form.getFieldValue("make");
        if (form.getFieldValue("make")) {
            modelsSearch({
                Make_Name: makeValue.value,
                search: value,
            })
                .then((resp) => {
                    setModels(resp);
                })
                .catch((err) => console.log("ERR", err));
        }
    };

    return (
        <div className="finder-container">
            <Form onFinish={submitForm} form={form}>
                <Row justify="center" gutter={20}>
                    <FormColumn
                        label={"Tow Date"}
                        field={
                            <Form.Item
                                name="range"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tow date is required",
                                    },
                                ]}
                            >
                                <RangePicker style={{ width: "100%" }} />
                            </Form.Item>
                        }
                    />
                    <FormColumn
                        label={"Year"}
                        field={
                            <Form.Item name="year">
                                <DatePicker
                                    style={{ width: "100%" }}
                                    format="YYYY"
                                    picker="year"
                                />
                            </Form.Item>
                        }
                    />
                    <FormColumn
                        label={"Make"}
                        field={
                            <DebounceSelect
                                debounceFunction={fetchMakes}
                                options={makes}
                                setDependent={setMakeSelected}
                                fieldName="make"
                            />
                        }
                    />
                    <FormColumn
                        label={"Model"}
                        field={
                            <DebounceSelect
                                debounceFunction={fetchModels}
                                dependent={makeSelected}
                                options={models}
                                fieldName="model"
                            />
                        }
                    />

                </Row>
                <Row justify="center" gutter={20}>
                    <FormColumn
                        label={"VIN"}
                        field={
                            <Form.Item name="vin">
                                <Input />
                            </Form.Item>
                        }
                    />
                    <FormColumn
                        label={"Plate#"}
                        field={
                            <Form.Item name="lp">
                                <Input />
                            </Form.Item>
                        }
                    />
                    <FormColumn
                        label={"Tow Location"}
                        field={
                            <Form.Item name="towLocation">
                                <Select allowClear
                                    showSearch>
                                    {states().map((state, index) => {
                                        return <Option value={state} key={'state-' + index}>{state}</Option>;
                                    })}

                                </Select>
                            </Form.Item>
                        }
                    />
                </Row>
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                        <Row justify="end">
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default TowForm;
